.about {
  margin-top: 102px;
  background-color: #fff;
  padding-bottom: 200px;
  position: relative;
  z-index: 1;
  padding-top: 70px;
}
.abInner {
  width: 90vw;
  margin: 0 auto;
  max-width: 1350px;
}
.ab-bg {
  position: absolute;
  top: -105px;
  left: 0%;
  width: 100vw;
  z-index: -2;
  opacity: 0.3;
}
.abText {
  text-align: center;
  h3 {
    color: #ff5402;
    margin-bottom: 40px;
  }
  h1 {
    font-weight: 800;
    max-width: 370px;
    margin: 10px auto;
  }
  p {
    max-width: 650px;
    margin: 0 auto;
    color: #000;
    font-weight: 600;
  }
  .knowText {
    max-width: 1200px;
    text-align: left;
    font-weight: 500;
    margin-top: 80px;
    color: #313131;
    line-height: 22px;
  }
  .btn {
    display: flex;
    align-items: center;
    margin: 10px auto;
    margin-top: 30px;
    svg {
      margin-left: 2px;
      fill: #fff;
      width: 100%;
      height: 100%;
      width: 18px;
    }
  }
}
.abGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  margin-top: 100px;
  .box {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.07);
    padding-bottom: 50px;
    img {
      width: 60px;
    }
    h4 {
      margin: 20px 0;
    }
    p {
      max-width: 250px;
      margin-top: 30px;
    }
  }
}
@media (max-width: 970px) {
  .abGrid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .abGrid {
    max-width: 400px;
    grid-template-columns: 1fr;
    margin: 0 auto;
    margin-top: 100px;
    .box {
      text-align: center;
      p {
        margin: 0 auto;
      }
    }
  }
  .abText {
    h1 {
      font-size: 28px;
    }
    h3 {
      font-size: 17px;
    }
    p {
      font-size: 15px;
    }
    .knowText {
      text-align: center;
    }
  }
}
