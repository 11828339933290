a {
  text-decoration: none;
}
.linkList {
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-right: 50px;
}
.linkList:nth-child(6) {
  margin-right: 0;
}
.logo-img {
  max-width: 240px;
}
.header {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  z-index: 20;
  background-color: #fff;
  ul {
    display: flex;
    // flex-direction: row;
    list-style: none;
  }
}
.link {
  margin-right: 2px;
  cursor: pointer;
}

.headerInner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 1350px;
  padding: 15px 0;
  width: 90vw;
  .link.btn {
    color: #fff;
    margin-right: 0;
  }
}

.btn {
  font-size: 14px;
}
.menu {
  position: absolute;
  top: 75px;
  background-color: #fff;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  padding: 20px;
  transform: translateX(-50%);
  transition: all 0.2s ease-in-out;
  z-index: 2;
  .cont {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;
    margin-bottom: 15px;
  }
  a {
    color: #000;
    font-weight: 500;
    span {
      margin-right: 10px;
      img {
        max-width: 36px;
      }
    }
  }
  .contBig2 {
    min-width: 160px;
    a {
      margin-bottom: 10px;
      &:nth-child(1) {
        margin-bottom: 18px;
      }
    }
  }
}
.navigation {
  display: flex;
  align-items: center;
  a {
    border: 2px solid #ff5402;
    padding: 12px 20px;
    font-weight: 600;
    border-radius: 8px;
    margin-right: 10px;
    font-size: 14px;
  }
  span {
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
      width: 25px;
    }
  }
}
.wsap {
  position: fixed;
  top: 88vh;
  right: 30px;
  border-radius: 50%;
  background-color: #fff;
  width: 45px;
  height: 45px;
  box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.129);
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1000px) {
  .header {
    background-color: #fff;
  }
  .linksCont {
    display: none;
  }
  .menu {
    display: none;
  }
  .lNav {
    position: absolute;
    top: 75px;
    left: 0;
    width: 100%;
    background: #fff;
  }
  .mobile-nav {
    display: flex;
    flex-direction: column;
    max-width: 90vw;
    margin: 0 auto;
    padding-bottom: 5px;
  }
  .sList {
    padding: 10px 0;
    font-weight: 600;
  }
  .opener {
    width: 100%;
    position: relative;
    cursor: pointer;
    &::after {
      position: absolute;
      content: url("../assets/chevron-black.svg");
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
    }
  }
  .hidden {
    margin-top: 10px;
    padding: 0 5px;
    div {
      padding: 5px 0;
    }
  }
}

@media (min-width: 1000px) {
  .lNav {
    display: none;
  }
  .navigation {
    display: none;
  }
}
@media (max-width: 600px) {
  .logo-img {
    max-width: 160px;
  }
}
@media (max-width: 400px) {
  .navigation {
    a {
      padding: 10px 14px;
    }
  }
}
