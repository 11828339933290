* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: #313131;
  -webkit-tap-highlight-color: transparent;
}
body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.btn {
  padding: 15px 20px;
  background: #ff5402;
  border-radius: 5px;
  display: inline-block;
  font-family: "Montserrat", sans-serif;
  outline: 0;
  cursor: pointer;
  border: none;
}
.cta {
  display: flex;
  align-items: center;
  color: #ff5402;
  svg {
    fill: #ff5402;
  }
}
img {
  max-width: 100%;
}
select {
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0;
  background-image: none;
  // position: relative;
  background: url("../assets/chevron-black.svg") right 10px top 50%/12px
    no-repeat;
}
p {
  color: #686868;
  font-weight: 500;
}
